import { MetadataTypes, ExcludedChurchesNotRequiringAgeGroupGender, LimitInvolvementOptionToHillsongIsHome } from '~app/constants/church';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { AppState } from '~store/app-state';
import { UserService } from '~app/services/auth/user.service';
import { MarlinSlideInOut } from '~core/animations';
import * as metadataAction from '~store/actions/app-data.actions';
import { MetaData } from '~models/meta-data';
import { ProgressSteps } from '~logic/ui/progress-steps';
import { InitialiseChurchLocationService } from '~models/church';
import { markAllTouched, markAllUntouched, NameRegex } from '~app/logical-objects/helpers/form';
import { TranslatePipe } from '~pipes/translate.pipe';
import * as moment from 'moment';
import { validateRequiredGroup, validateFormHasValues, validateEmail, validatePhone } from '~app/core/validators';
import { environment } from '~environments/environment';
import { handleMapiError } from '~app/logical-objects/helpers/system';
import { is16OrOver, formatDateForHapi } from '~app/logical-objects/helpers/date-time';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'marlin-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    animations: MarlinSlideInOut
})
export class RegisterComponent implements OnInit {
    ageGroups: MetaData = { metaType: '', options: [] };
    churchLocationServiceForm: FormGroup;
    errorMessage = '';
    genders: MetaData = { metaType: '', options: [] };
    involvementLevels = {
        homeChurch: { value: 'HH', visible: true },
        newPerson: { value: 'NP', visible: true },
        connectGroup: { value: 'CG', visible: true },
        volunteer: { value: 'VOL', visible: true }
    };
    minDate = { year: 1900, month: 1 };
    maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    startDate = { year: new Date().getFullYear() - 16, month: new Date().getMonth() + 1 };
    initialiseChurchLocationService = new InitialiseChurchLocationService();
    isAgeGroupGenderRequired = false;
    labels = {
        ageGroup: '',
        gender: ''
    };
    policiesUrl = `${environment.myhillsongUrl}/Policies.aspx`;
    progressSteps = new ProgressSteps(5, this.store);
    registrationSuccess = false;
    registerForm: FormGroup;
    steps = {
        NameDetails: { text: 'Basic Info', value: 3 },
        signUpIntention: { text: 'Connect', value: 2 },
        churchInfo: { text: 'Church Info', value: 1 },
        contactDetails: { text: 'Contact', value: 4 },
        accountCreate: { text: 'Final', value: 5 }
    };

    minNameLength = 2;

    constructor(
        private userService: UserService,
        private fb: FormBuilder,
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.buildForm();

        this.activatedRoute.queryParams.subscribe(params => {
            // Set values for all possible query string parameters that arrive on this page.
            const token = params['tk'];
            this.registerForm.controls['invitationToken'].setValue(token);
        });

        this.store.select(state => state.metadata[MetadataTypes.Gender]).subscribe(data => {
            if (data) {
                this.genders = data;
            }
        });

        this.store.select(state => state.metadata[MetadataTypes.AgeGroup]).subscribe(data => {
            if (data) {
                this.ageGroups = data;
                this.ageGroups.options.sort((a, b) => {
                    return parseFloat(b.value) - parseFloat(a.value);
                });
            }
        });

        // Set inital select dropdownvalue.
        this.labels.ageGroup = new TranslatePipe(this.store).transform('gblPleaseSelect');
        this.labels.gender = new TranslatePipe(this.store).transform('gblPleaseSelect');

        this.registerForm.get('churchLocationService').valueChanges.subscribe(value => {
            if (value.controls.church.value) {
                this.churchLocationServiceChange();
            }
        });
    }

    buildForm() {
        this.registerForm = this.fb.group(
            {
                loginEmail: ['', [Validators.required, validateEmail]],
                firstName: [
                    '',
                    [Validators.required, Validators.minLength(this.minNameLength), Validators.pattern(NameRegex)]
                ],
                familyName: [
                    '',
                    [Validators.required, Validators.minLength(this.minNameLength), Validators.pattern(NameRegex)]
                ],
                churchLocationService: [null, validateFormHasValues],
                // Currently not used but we need to capture this information to customise the user experience.
                homePhone: ['', validatePhone],
                mobilePhone: ['', validatePhone],
                dateOfBirth: ['', validateFormHasValues],
                ageGroupId: ['', Validators.required],
                genderId: ['', Validators.required],
                policyAcceptance: ['', Validators.requiredTrue],
                involvementId: ['', Validators.required],
                recaptcha: ['', Validators.required],
                invitationToken: [''],
                consents: {
                    consentType1: false,
                    consentType2: false,
                    consentType3: false,
                    consentType4: false,
                    consentType5: false,
                    consentType6: false
                }
            },
            { validator: validateRequiredGroup(['homePhone', 'mobilePhone']) }
        );
    }

    signUpIntentionSelected(intentionType: number) {
        this.registerForm.get('signUpIntention').setValue(intentionType);
        this.progressSteps.nextStep();
    }

    IsNameDetailsStep() {
        return this.progressSteps.currentStep === this.steps.NameDetails.value;
    }

    IsChurchInfoStep() {
        return this.progressSteps.currentStep === this.steps.churchInfo.value;
    }

    IsContactDetailsStep() {
        return this.progressSteps.currentStep === this.steps.contactDetails.value;
    }

    IsSignUpIntentionsStep() {
        return this.progressSteps.currentStep === this.steps.signUpIntention.value;
    }

    IsAccountCreatedStep() {
        return this.progressSteps.currentStep === this.steps.accountCreate.value;
    }

    next() {
        // Depending on step, check if the form is filled out correctly before allowing the user to move on.
        switch (this.progressSteps.currentStep) {
            case this.steps.NameDetails.value:
                if (
                    this.registerForm.controls['ageGroupId'].invalid ||
                    this.registerForm.controls['genderId'].invalid ||
                    this.registerForm.controls['firstName'].invalid ||
                    this.registerForm.controls['familyName'].invalid ||
                    this.registerForm.controls['dateOfBirth'].invalid
                ) {
                    markAllTouched(this.registerForm);
                    return;
                } else {
                    const dateOfBirth = moment(formatDateForHapi(this.registerForm.get('dateOfBirth').value.value));
                    if (!is16OrOver(dateOfBirth)) {
                        // under 16
                        // this.registerForm.reset();
                        this.router.navigate(['young']);
                    }
                }
                break;
            case this.steps.churchInfo.value:
                if (this.registerForm.get('churchLocationService').value.invalid) {
                    markAllTouched(this.registerForm.get('churchLocationService').value);
                    return;
                }
                break;
            case this.steps.contactDetails.value:
                if (
                    this.registerForm.controls['loginEmail'].invalid ||
                    this.registerForm.getError('validateRequiredGroup') ||
                    this.registerForm.controls.homePhone.invalid ||
                    this.registerForm.controls.mobilePhone.invalid
                ) {
                    markAllTouched(this.registerForm);
                    return;
                }
                break;
        }
        markAllUntouched(this.registerForm);
        if (this.progressSteps.currentStep == this.steps.accountCreate.value) {
            this.submit();
        } else {
            this.progressSteps.nextStep();
        }
    }

    churchLocationServiceChange() {
        if (this.registerForm.get('churchLocationService').value) {
            this.store.dispatch(
                new metadataAction.LoadMetadata(
                    MetadataTypes.Gender,
                    this.registerForm.controls.churchLocationService.value.controls.church.value
                )
            );

            this.store.dispatch(
                new metadataAction.LoadMetadata(
                    MetadataTypes.AgeGroup,
                    this.registerForm.controls.churchLocationService.value.controls.church.value
                )
            );

            // Configure involvement options.
            const locationId = Number(this.registerForm.get('churchLocationService').value.get('location').value);
            const showNonHillsongIsHomeButtons =
                LimitInvolvementOptionToHillsongIsHome.indexOf(locationId) == -1;
            const involvementControl = this.registerForm.get('involvementId');

            this.involvementLevels.connectGroup.visible = showNonHillsongIsHomeButtons;
            this.involvementLevels.newPerson.visible = showNonHillsongIsHomeButtons;
            this.involvementLevels.volunteer.visible = showNonHillsongIsHomeButtons;

            involvementControl.updateValueAndValidity();

            // Configure age group & gender options.
            const churchId = Number(this.registerForm.get('churchLocationService').value.get('church').value);
            this.isAgeGroupGenderRequired =
                ExcludedChurchesNotRequiringAgeGroupGender.indexOf(churchId) == -1;

            //// Check if it is an excluded church then don't make gender and age group required.
            const genderControl = this.registerForm.get('genderId');
            const ageGroupControl = this.registerForm.get('ageGroupId');
            if (this.isAgeGroupGenderRequired) {
                genderControl.setValidators(Validators.required);
                ageGroupControl.setValidators(Validators.required);
            } else {
                genderControl.clearValidators();
                ageGroupControl.clearValidators();
            }
            genderControl.updateValueAndValidity();
            ageGroupControl.updateValueAndValidity();
        }
    }

    submit() {
        if (this.registerForm.valid) {
            this.store.dispatch(new metadataAction.ToggleIsLoading(true));
            this.userService.registerUser(this.registerForm.value).subscribe(
                data => {
                    this.registrationSuccess = true;
                },
                err => handleMapiError(this.store, err.error.status),
                () => {
                    this.store.dispatch(new metadataAction.ToggleIsLoading(false));
                }
            );
        } else {
            markAllTouched(this.registerForm);
        }
    }

    markAllUntouched() {
        markAllUntouched(this.registerForm);
        this.errorMessage = '';
    }
}
