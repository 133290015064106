<form [formGroup]="additionalInfoForm" novalidate>
    <div class="form-title" [hidden]="!IsAddtionalInfo1Step() && !IsAddtionalInfo2Step()">
        <h2 [innerHTML]='"gblCompleteYourProfile" | translate'></h2>
    </div>
    <div *ngIf="IsDobStep()">
        <div class="form-title">
            <h2 [innerHTML]='"gblDateOfBirthRequiredMsg" | translate'></h2>
        </div>
        <marlin-date-dropdown formControlName="dateOfBirth"></marlin-date-dropdown>
        <div class="button-selection">
            <div class="form-group">
                <button class="btn btn-primary w-100" (click)="dobCheck()" [disabled]="additionalInfoForm.get('dateOfBirth').invalid" [innerHTML]="'gblNext' | translate"></button>
            </div>
        </div>
    </div>

    <div *ngIf="progressSteps.currentStep === steps.initial && !this.isGlobalUser">
        <div class="form-title">
            <h1 [innerHTML]="('gblHello' | translate) + ', ' + additionalInfoForm.get('firstName').value + '!'"></h1>
        </div>
        <div class="button-selection">
            <div class="form-group">
                <button class="btn btn-primary w-100" (click)="userSelect(userSelection.MF.value)" [innerHTML]="'gblManageMyFamily' | translate"></button>
            </div>
            <div class="form-group">
                <button class="btn btn-primary w-100" (click)="userSelect(userSelection.AC.value)" [innerHTML]="'gblRegisterForEngage' | translate"></button>
            </div>
            <div class="form-group">
                <button class="btn btn-primary w-100" (click)="userSelect(userSelection.CG.value)" [innerHTML]="'gblJoinAConnectGroup' | translate"></button>
            </div>
            <div class="form-group">
                <button class="btn btn-primary w-100" (click)="userSelect(userSelection.VT.value)" [innerHTML]="'gblJoinAVolunteerTeam' | translate"></button>
            </div>
            <div class="form-group">
                <button class="btn btn-primary w-100" (click)="userSelect(userSelection.CM.value)" [innerHTML]="'gblMoreOptions' | translate"></button>
            </div>
        </div>
    </div>

    <div [hidden]="!IsAddtionalInfo1Step()" [@marlinSlideInOut]="IsAddtionalInfo1Step() ? 'current' : progressSteps.currentStep === steps.involvementLevel ? 'previous' : 'next'">
        <div class="form-group">
            <label [innerHTML]='"gblPhoneNumber" | translate'></label>
            <marlin-input-group [setError]="additionalInfoForm.errors?.validateRequiredGroup && additionalInfoForm.controls.homePhone.touched && additionalInfoForm.controls.mobilePhone.touched"
                [iconClass]="'fa fa-mobile'" [type]="'tel'" formControlName='mobilePhone' [placeholder]="'gblMobilePhone'" [errorMessage]='"gblMobilePhoneFormatErrorMsg"'></marlin-input-group>
            <span class="label-separator" [innerHTML]="'gblOR' | translate"></span>
            <marlin-input-group [setError]="additionalInfoForm.errors?.validateRequiredGroup && additionalInfoForm.controls.homePhone.touched && additionalInfoForm.controls.mobilePhone.touched"
                [iconClass]="'fa fa-phone'" [type]="'tel'" formControlName='homePhone' [placeholder]="'gblHomePhone'" [errorMessage]='"gblHomePhoneFormatErrorMsg"'></marlin-input-group>
            <div class="alert alert-danger" *ngIf="additionalInfoForm.errors?.validateRequiredGroup && additionalInfoForm.controls.homePhone.touched && additionalInfoForm.controls.mobilePhone.touched"
                [innerHTML]='"gblPhoneOrMobileErrorMsg" | translate'>
            </div>
        </div>
        <marlin-input [label]="'gblAddress'" formControlName="address"></marlin-input>
        <marlin-input [label]="'gblSuburb'" [errorMessage]="'gblAddressSuburbErrorMsg'" formControlName="suburb"></marlin-input>
        <marlin-input [label]="'gblAddressPostcode'" [errorMessage]="'gblAddressPostcodeErrorMsg'" formControlName="postCode"></marlin-input>
        <marlin-dropdown (onSelect)="additionalInfoForm.controls.countryId = $event" formControlName="countryId" [data]="countries.options"
            [title]="'gblCountryRegion'" [errorMessage]="'gblCountryRegionErrorMsg'"></marlin-dropdown>
        <div class="form-group">
            <button class="btn btn-primary w-100" (click)="additionalInfo1Next()" [innerHTML]='"gblNext" | translate'></button>
        </div>
    </div>

    <div [hidden]="!IsAddtionalInfo2Step()" [@marlinSlideInOut]="IsAddtionalInfo2Step() ? 'current' : progressSteps.currentStep === steps.additionalInfo1 ? 'previous' : 'next'">
        <marlin-dropdown (onSelect)="additionalInfoForm.controls.genderId = $event" formControlName="genderId" [data]="(genders$ | async)?.options"
            [title]="'gblGender'" [errorMessage]="'gblGenderErrorMsg'" [toolTip]="'ttThisWillHelpUsTo' | translate"></marlin-dropdown>

        <marlin-dropdown (onSelect)="additionalInfoForm.controls.ageGroupId = $event" formControlName="ageGroupId" [data]="ageGroups.options"
            [title]="'gblAgeGroup'" [errorMessage]="'gblAgeGroupErrorMsg'"></marlin-dropdown>

        <div class="form-group">
            <button [disabled]="additionalInfoForm.get('genderId').invalid || additionalInfoForm.get('ageGroupId').invalid" class="btn btn-primary w-100"
                (click)="submit()" [innerHTML]='(isGlobalUser ? "gblNext" : "lclSubmit")  | translate'></button>
        </div>
    </div>

    <div *ngIf="progressSteps.currentStep > 1" class="form-navigation form-group text-center">
        <a href="javascript: void(0)" (click)="progressSteps.previousStep();">
            <i class="far fa-long-arrow-left fa-2x"></i>
            <span [innerHTML]="'gblBack' | translate"></span>
        </a>
    </div>
</form>