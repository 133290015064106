<form [formGroup]="registerForm" (onsubmit)="next()" *ngIf="!registrationSuccess" novalidate>
    <div class="marlin-slide">
        <div *ngIf="errorMessage != ''" class="alert alert-danger" [innerHTML]='errorMessage | translate'>
        </div>
        <div class="marlin-slide--item" [hidden]="!IsNameDetailsStep()" [@marlinSlideInOut]="IsNameDetailsStep() ? 'current' : progressSteps.currentStep > steps.NameDetails.value ? 'next' : 'previous'">
            <div class="form-title">
                <h2 [innerHTML]='"gblTellUsAboutYourself" | translate'></h2>
            </div>
            <marlin-input [label]="'gblFirstName'" [errorMessage]="'gblFirstNameErrorMsg'" formControlName="firstName"></marlin-input>
            <marlin-input [label]="'gblLastName'" [errorMessage]="'gblLastNameErrorMsg'" formControlName="familyName"></marlin-input>
            <marlin-date-dropdown formControlName="dateOfBirth"></marlin-date-dropdown>
            <div>
                <marlin-dropdown formControlName="genderId" [data]="genders.options" [title]="'gblGender'" [errorMessage]="'gblGenderErrorMsg'"
                    [toolTip]="'ttThisWillHelpUsTo' | translate"></marlin-dropdown>
                <marlin-dropdown (onSelect)="registerForm.controls.ageGroupId = $event" formControlName="ageGroupId" [data]="ageGroups.options"
                    [toolTip]="'ttThisWillHelpUsTo' | translate" [title]="'gblAgeGroup'" [errorMessage]="'gblAgeGroupErrorMsg'"></marlin-dropdown>
            </div>
            <div class="form-group">
                <button type="submit" class="btn btn-primary w-100" (click)="next()" [innerHTML]="'gblNext' | translate"></button>
            </div>
        </div>

        <div class="marlin-slide--item" [hidden]="!IsSignUpIntentionsStep()" [@marlinSlideInOut]="IsSignUpIntentionsStep() ? 'current' : progressSteps.currentStep > steps.signUpIntention.value ? 'next' : 'previous'">
            <div class="form-title">
                <h2>
                    <span [innerHTML]='"gblHowCanWeHelpYou" | translate'></span>?</h2>
            </div>
            <div class="form-group">
                <div class="btn-group-verticle btn-group-toggle" role="group" ngbRadioGroup name="involvementId" formControlName="involvementId">
                    <label ngbButtonLabel *ngIf="involvementLevels.newPerson.visible" class="btn-choices w-100 d-flex align-items-center justify-content-center">
                        <input ngbButton type="radio" [value]="involvementLevels.newPerson.value">
                        <span [innerHTML]='"gblMakeHillsongHome" | translate'></span>
                    </label>
                    <label ngbButtonLabel *ngIf="involvementLevels.homeChurch.visible"  class="btn-choices w-100 d-flex align-items-center justify-content-center">
                        <input ngbButton type="radio" [value]="involvementLevels.homeChurch.value">
                        <span [innerHTML]='"gblHillsongIsHomeChurch" | translate'></span>
                    </label>
                    <label ngbButtonLabel *ngIf="involvementLevels.connectGroup.visible"  class="btn-choices w-100 d-flex align-items-center justify-content-center">
                        <input ngbButton type="radio" [value]="involvementLevels.connectGroup.value">
                        <span [innerHTML]='"gblIAmLookingForCG" | translate'></span>
                    </label>
                    <label ngbButtonLabel *ngIf="involvementLevels.volunteer.visible" class="btn-choices w-100 d-flex align-items-center justify-content-center">
                        <input ngbButton type="radio" [value]="involvementLevels.volunteer.value">
                        <span [innerHTML]='"gblIAmLookingForVT" | translate'></span>
                    </label>
                </div>
            </div>
            <div class="form-group">
                <button type="submit" class="btn btn-primary w-100" (click)="next()" [disabled]="registerForm.get('involvementId').invalid"
                    [innerHTML]="'gblNext' | translate"></button>
            </div>
        </div>

        <div class="marlin-slide--item" [hidden]="!IsChurchInfoStep()" [@marlinSlideInOut]="IsChurchInfoStep() ? 'current' : progressSteps.currentStep> steps.churchInfo.value ? 'next' : 'previous'">
            <div class="form-title">
                <h2 [innerHTML]='"gblTellUsServiceAttendance" | translate'></h2>
            </div>
            <marlin-church-location-service formControlName='churchLocationService'></marlin-church-location-service>
            <div class="form-group">
                <button type="submit" class="btn btn-primary w-100" (click)="next()" [innerHTML]="'gblNext' | translate"></button>
            </div>
        </div>

        <div class="marlin-slide--item" [hidden]="!IsContactDetailsStep()" [@marlinSlideInOut]="IsContactDetailsStep() ? 'current' : progressSteps.currentStep > steps.accountCreate.value ? 'next' : 'previous'">
            <div class="form-title">
                <h2 [innerHTML]='"gblTellUsHowToConnect" | translate'></h2>
            </div>
            <marlin-input [label]="'gblEmail'" [helpText]="'gblAlsoUserName'" [type]="'email'" [errorMessage]="'gblEmailAddressRequiredErrorMsg'"
                formControlName="loginEmail"></marlin-input>
            <div class="form-group">
                <label [ngClass]="{'marlin-required': registerForm.errors?.validateRequiredGroup}" [innerHTML]="'gblPhoneNumber' | translate"></label>
                <marlin-input-group [setError]="registerForm.errors?.validateRequiredGroup && registerForm.controls.homePhone.touched && registerForm.controls.mobilePhone.touched"
                    [iconClass]="'fa fa-mobile'" [type]="'tel'" formControlName="mobilePhone" [placeholder]="'gblMobilePhone'"
                    [errorMessage]='"gblMobilePhoneFormatErrorMsg"'></marlin-input-group>
                <span class="label-separator">{{"gblOR" | translate}}</span>
                <marlin-input-group [setError]="registerForm.errors?.validateRequiredGroup && registerForm.controls.homePhone.touched && registerForm.controls.mobilePhone.touched"
                    [iconClass]="'fa fa-phone'" [type]="'tel'" formControlName="homePhone" [placeholder]="'gblHomePhone'" [errorMessage]='"gblHomePhoneFormatErrorMsg"'></marlin-input-group>
                <div class="alert alert-danger" *ngIf="registerForm.errors?.validateRequiredGroup && registerForm.controls.homePhone.touched && registerForm.controls.mobilePhone.touched"
                    [innerHTML]='"gblPhoneOrMobileErrorMsg" | translate'>
                </div>
            </div>
            <marlin-consents formControlName="consents"></marlin-consents>
            <div class="form-group">
                <button type="submit" class="btn btn-primary w-100" (click)="next()" [innerHTML]="'gblNext' | translate"></button>
            </div>
        </div>

        <div class="marlin-slide--item" [hidden]="!IsAccountCreatedStep()" [@marlinSlideInOut]="IsAccountCreatedStep() ? 'current' : progressSteps.currentStep > steps.accountCreate.value ? 'next' : 'previous'">
            <div class="form-title">
                <h2 [innerHTML]='"gblAlmostThere" | translate'></h2>
            </div>
            <div class="form-group">
                <div class="checkbox terms-conditions">
                    <input type="checkbox" value="1" id="policyAcceptance" formControlName="policyAcceptance" />
                    <label for="policyAcceptance">
                        <span [innerHTML]="'gblCreateAccountAgreement' | translate"></span>
                        <a class="a--padding-left" href="{{policiesUrl}}" target="_blank" [innerHtml]='"gblPrivacyDataCookiePolicyTC" | translate'></a>.
                        <span [innerHTML]="'gblAtLeast16Years' | translate"></span>
                    </label>
                </div>
                <div class="alert alert-danger" *ngIf="registerForm.get('policyAcceptance').hasError('required') && registerForm.get('policyAcceptance').touched"
                    [innerHTML]='"gblTnCCookieDataPolicyErrorMsg" | translate'>
                </div>
            </div>
            <div class="form-group">
                <div class="recaptcha">
                    <re-captcha id="recaptcha" formControlName="recaptcha" siteKey="6LerCzEUAAAAAKOi1IQFyq_0J8TT5VrcnnRAnnRD"></re-captcha>
                </div>
            </div>
            <div class="form-group">
                <button class="btn btn-primary w-100" type="submit" (click)="next()" [innerHTML]="'gblCreateAccount' | translate"></button>
            </div>
        </div>
        <div *ngIf="!progressSteps.isFirstStep" class="form-navigation d-flex justify-content-between">
            <marlin-home-button></marlin-home-button>
            <a href="javascript: void(0)" (click)="progressSteps.previousStep(); markAllUntouched(registerForm)">
                <i class="far fa-long-arrow-left fa-2x"></i>
                <span [innerHTML]="'gblBack' | translate"></span>
            </a>
        </div>
    </div>
    <div *ngIf="progressSteps.isFirstStep || registrationSuccess" class="text-center form-navigation">
        <marlin-home-button></marlin-home-button>
    </div>
    <div class="form-group">
        <label [innerHTML]="('gblProgress' | translate) + ': ' + progressSteps.progressValue + '%'"></label>
        <ngb-progressbar type="info" [value]="this.progressSteps.progressValue"></ngb-progressbar>
    </div>
</form>

<div *ngIf="registrationSuccess">
    <div class="form-title">
        <h1>
            <b [innerHTML]="('gblThankYouForRegistering' | translate)"></b>
        </h1>
        <h3>
            <span [innerHTML]="'gblNextStepInstructionEmail' | translate"></span>
            <b> {{registerForm.controls.loginEmail.value}} </b>
            <span [innerHTML]="('gblForNextSteps' | translate)"></span>
            <span [innerHTML]="('gblReviewIn24Hours' | translate)"></span>
        </h3>
    </div>
    <div class="form-group text-center form-navigation">
        <marlin-home-button></marlin-home-button>
    </div>
</div>