import { CookieService } from 'ngx-cookie';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as userActions from '~store/actions/user.actions';
import { ProgressSteps } from '~logic/ui/progress-steps';
import { MetadataTypes } from '~constants/church';
import * as metadataAction from '~store/actions/app-data.actions';
import * as appDataActions from '~store/actions/app-data.actions';
import { TranslatePipe } from '~pipes/translate.pipe';
import { markAllTouched, setNullValuesAsEmpty, markAllUntouched } from '~app/logical-objects/helpers/form';
import { UserService } from '~services/auth/user.service';
import { AuthTokenName } from '~constants/app-config';
import { environment } from '~environments/environment';
import { validateRequiredGroup, validatePhone, validateFormHasValues } from '~app/core/validators';
import { MyHillsongLoginPassThroughInvolvementIds } from '~constants/user';
import { handleMapiError } from '~app/logical-objects/helpers/system';
import { MyHillsongUrl } from '~app/constants/url';
import { UserMetaData } from '~app/constants/bit-data';
import { InitialiseChurchLocationService } from '~app/models/church';
import { is16OrOver, formatDateForHapi } from '~app/logical-objects/helpers/date-time';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(router, userService, store, cookieService, activatedRoute, fb) {
        this.router = router;
        this.userService = userService;
        this.store = store;
        this.cookieService = cookieService;
        this.activatedRoute = activatedRoute;
        this.fb = fb;
        this.additionalInfoForm = this.fb.group({
            firstName: ['', Validators.required],
            familyName: ['', Validators.required],
            ageGroupId: ['', Validators.required],
            genderId: ['', Validators.required],
            dateOfBirth: [null, Validators.required],
            homePhone: ['', validatePhone],
            mobilePhone: ['', validatePhone],
            address: [''],
            suburb: ['', Validators.required],
            postCode: ['', Validators.required],
            countryId: ['', Validators.required],
            involvementId: ['', Validators.required],
            churchLocationService: [null, validateFormHasValues],
            consents: {
                consentType1: false,
                consentType2: false,
                consentType3: false,
                consentType4: false,
                consentType5: false,
                consentType6: false
            }
        }, { validator: validateRequiredGroup(['homePhone', 'mobilePhone']) });
        this.initialiseChurchLocationService = new InitialiseChurchLocationService();
        this.ageGroups = { metaType: '', options: [] };
        this.maxSignupAge = moment().subtract(13, 'year');
        this.genders$ = this.store.select(function (state) { return state.metadata[MetadataTypes.Gender]; });
        this.countries = { metaType: '', options: [] };
        this.isProfileComplete = false;
        this.isGlobalUser = false;
        this.hasDob = false;
        this.labels = {
            gender: '',
            ageGroup: ''
        };
        this.loginHandlerString = '/Utilities/LoginHandler.ashx?nonce=';
        this.minDate = { year: 1900, month: 1, day: 1 };
        this.maxDate = {
            year: moment().year(),
            month: moment().month(),
            day: moment().date()
        };
        this.redirectUrl = '';
        this.progressSteps = new ProgressSteps(3, this.store);
        this.steps = {
            dobCheck: null,
            initial: 1,
            additionalInfo1: 2,
            additionalInfo2: 3,
            churchLocationService: null,
            involvementLevel: null,
            consents: null
        };
        this.token = window.sessionStorage.getItem(AuthTokenName);
        this.userSelection = {
            CG: { value: 'ConnectGroup', url: '/ConnectGroups/Search.aspx' },
            VT: { value: 'VolunteerTeam', url: '/Volunteers/JoinATeam.aspx' },
            AC: { value: 'AlphaCourse', url: '/Registrations/CourseRegistration.aspx' },
            MF: { value: 'ManageFamily', url: '/MyProfile/MyFamily.aspx' },
            CM: { value: 'ClassicMyhillsong', url: '/' }
        };
        this.involvementLevels = {
            homeChurch: 'HH',
            newPerson: 'NP',
            connectGroup: 'CG',
            volunteer: 'VOL'
        };
        this.isAgeGroupGenderRequired = false;
    }
    HomeComponent.prototype.ngOnInit = function () {
        // Set timeout is required to avoid change detection errors.
        // tslint:disable-next-line
        // See https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4.
        var _this = this;
        this.store.select(function (state) { return state.user; }).subscribe(function (data) {
            if (data.status == 'failed') {
                _this.router.navigate(['/login']);
            }
            else if (!_.isEmpty(data)) {
                var dob = null;
                if (data.dateOfBirth) {
                    var dobDate = moment(data.dateOfBirth);
                    dob = {
                        year: dobDate.year(),
                        month: dobDate.month() + 1,
                        day: dobDate.date()
                    };
                    _this.hasDob = true;
                }
                // Setting values for the form requires a value for each field, but the values cannot be null.
                var formattedData = setNullValuesAsEmpty(data);
                _this.additionalInfoForm.setValue({
                    firstName: formattedData.firstName,
                    familyName: formattedData.familyName,
                    ageGroupId: formattedData.ageGroupId.toString(),
                    genderId: formattedData.genderId.toString(),
                    homePhone: formattedData.homePhone,
                    mobilePhone: formattedData.mobilePhone,
                    // Only set DOB if it is valid, otherwise some of the form data will be in an invalid state.
                    dateOfBirth: dob ? _this.fb.group(dob) : null,
                    address: formattedData.address1,
                    suburb: formattedData.suburb,
                    postCode: formattedData.postCode,
                    countryId: formattedData.countryId.toString(),
                    involvementId: formattedData.involvementId,
                    churchLocationService: formattedData.serviceId
                        ? {
                            church: formattedData.churchId,
                            location: formattedData.locationId,
                            service: formattedData.serviceId
                        }
                        : { church: null, location: null, service: null },
                    consents: {
                        consentType1: false,
                        consentType2: false,
                        consentType3: false,
                        consentType4: false,
                        consentType5: false,
                        consentType6: false
                    }
                });
                _this.isProfileComplete = _this.additionalInfoForm.valid;
                // Check that the user has an involvement level of higher than ministry
                // active to pass them straight through to myhillsong.
                var isPassThroughToMyHillsong = MyHillsongLoginPassThroughInvolvementIds.indexOf(_this.additionalInfoForm.get('involvementId').value);
                // If is connection zone user then pass them straight through to the connection zone page.
                if (data.metadata & UserMetaData.isConnectionZoneUser) {
                    _this.userService.getNonce(_this.token).subscribe(function (resp) {
                        window.location.href = _this.getMyhillsongLoginHandlerString(resp.nonce) + "&ReturnUrl=" + MyHillsongUrl.connectionZone;
                    });
                }
                else if ((_this.isProfileComplete && isPassThroughToMyHillsong >= 0) ||
                    // If the user has not accepted terms and conditions we immediately redirect them to myhillsong
                    // which then will force them to read T&C.
                    data.metadata & UserMetaData.isTermsAndConditionsToBeAccepted) {
                    var returnUrl_1 = '';
                    var embeddedParam_1 = '';
                    _this.store.dispatch(new appDataActions.ToggleIsRedirecting(true));
                    _this.activatedRoute.queryParams.take(1).subscribe(function (params) {
                        returnUrl_1 =
                            params['ReturnUrl'] || params['returnUrl'] || params['Returnurl'] || params['returnurl'];
                    });
                    if (returnUrl_1 && returnUrl_1.includes('FrontPlugin')) {
                        embeddedParam_1 = '&embedded=true';
                    }
                    _this.userService.getNonce(_this.token).subscribe(function (resp) {
                        window.location.href = _this.getMyhillsongLoginHandlerString(resp.nonce) + "&ReturnUrl=" + (returnUrl_1 ? returnUrl_1 : '') + embeddedParam_1;
                    });
                }
                else {
                    _this.store.dispatch(new appDataActions.ToggleIsRedirecting(false));
                }
            }
            _this.store.dispatch(new metadataAction.LoadMetadata(MetadataTypes.Gender));
            _this.store.dispatch(new metadataAction.LoadMetadata(MetadataTypes.AgeGroup, data.churchId));
            _this.store.dispatch(new metadataAction.LoadMetadata(MetadataTypes.Country));
        });
        this.store.select(function (state) { return state.metadata[MetadataTypes.AgeGroup]; }).subscribe(function (data) {
            if (data) {
                _this.ageGroups = data;
                _this.ageGroups.options.sort(function (a, b) {
                    return parseFloat(b.value) - parseFloat(a.value);
                });
            }
        });
        this.store.select(function (state) { return state.metadata[MetadataTypes.Country]; }).subscribe(function (data) {
            if (data) {
                _this.countries = data;
            }
        });
        this.labels.ageGroup = new TranslatePipe(this.store).transform('gblPleaseSelect');
        this.labels.gender = new TranslatePipe(this.store).transform('gblPleaseSelect');
        this.store
            .select(function (store) { return store.user.metadata; })
            .take(1)
            .subscribe(function (data) {
            if (!!(data & UserMetaData.isGlobalUser)) {
                _this.isGlobalUser = true;
                _this.router.navigate(['/login']);
            }
            else if (!_this.hasDob) {
                _this.progressSteps = new ProgressSteps(4, _this.store);
                _this.steps = Object.assign({}, {
                    dobCheck: 0,
                    initial: 1,
                    churchLocationService: null,
                    involvementLevel: null,
                    additionalInfo1: 2,
                    additionalInfo2: 3,
                    consents: null
                });
                _this.progressSteps.goToStep(_this.steps.dobCheck);
            }
        });
    };
    HomeComponent.prototype.IsAddtionalInfo1Step = function () {
        return this.progressSteps.currentStep === this.steps.additionalInfo1;
    };
    HomeComponent.prototype.IsAddtionalInfo2Step = function () {
        return this.progressSteps.currentStep === this.steps.additionalInfo2;
    };
    HomeComponent.prototype.IsDobStep = function () {
        return this.progressSteps.currentStep === this.steps.dobCheck;
    };
    HomeComponent.prototype.userSelect = function (type) {
        var _this = this;
        if (this.isProfileComplete) {
            this.userService.getNonce(this.token).subscribe(function (resp) {
                switch (type) {
                    case _this.userSelection.MF.value:
                        _this.redirectUrl = _this.getMyhillsongLoginHandlerString(resp.nonce) + "&ReturnUrl=" + _this.userSelection.MF.url;
                        break;
                    case _this.userSelection.CG.value:
                        _this.redirectUrl = _this.getMyhillsongLoginHandlerString(resp.nonce) + "&ReturnUrl=" + _this.userSelection.CG.url;
                        break;
                    case _this.userSelection.VT.value:
                        _this.redirectUrl = _this.getMyhillsongLoginHandlerString(resp.nonce) + "&ReturnUrl=" + _this.userSelection.VT.url;
                        break;
                    case _this.userSelection.AC.value:
                        _this.redirectUrl = _this.getMyhillsongLoginHandlerString(resp.nonce) + "&ReturnUrl=" + _this.userSelection.AC.url;
                        break;
                    case _this.userSelection.CM.value:
                        _this.redirectUrl = _this.getMyhillsongLoginHandlerString(resp.nonce);
                        break;
                }
                window.location.href = _this.redirectUrl;
            });
        }
        else {
            this.progressSteps.goToStep(this.steps.additionalInfo1);
        }
    };
    HomeComponent.prototype.getMyhillsongLoginHandlerString = function (nonce) {
        return environment.myhillsongUrl + this.loginHandlerString + nonce;
    };
    HomeComponent.prototype.additionalInfo1Next = function () {
        if (this.additionalInfoForm.get('suburb').valid &&
            this.additionalInfoForm.get('postCode').valid &&
            this.additionalInfoForm.get('countryId').valid &&
            !this.additionalInfoForm.hasError('validateRequiredGroup') &&
            this.additionalInfoForm.controls.homePhone.valid &&
            this.additionalInfoForm.controls.mobilePhone.valid) {
            this.progressSteps.nextStep();
            markAllUntouched(this.additionalInfoForm);
        }
        else {
            markAllTouched(this.additionalInfoForm);
        }
    };
    HomeComponent.prototype.updateUser = function () {
        var _this = this;
        if (this.additionalInfoForm.valid) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            this.userService.updateUser(this.additionalInfoForm.value).subscribe(function (data) {
                _this.userService.getNonce(_this.token).subscribe(function (resp) {
                    window.location.href = _this.getMyhillsongLoginHandlerString(resp.nonce);
                }, function (err) { return handleMapiError(_this.store, err.error.status); });
            }, function (err) { return handleMapiError(_this.store, err.error.status); }, function () { return _this.store.dispatch(new appDataActions.ToggleIsLoading(true)); });
        }
        else {
            markAllTouched(this.additionalInfoForm);
        }
    };
    HomeComponent.prototype.gotoHillsongDotCom = function () {
        window.location.href = 'https://hillsong.com';
    };
    HomeComponent.prototype.dobCheck = function () {
        if (this.additionalInfoForm.get('dateOfBirth').value.invalid) {
            markAllTouched(this.additionalInfoForm.get('dateOfBirth').value);
        }
        var dateOfBirth = moment(formatDateForHapi(this.additionalInfoForm.get('dateOfBirth').value.value));
        if (is16OrOver(dateOfBirth)) {
            // 16 years or older, continue to next step.
            this.progressSteps.nextStep();
        }
        else {
            // under 16 - prevent user login.
            this.store.dispatch(new userActions.ResetUserData());
            window.sessionStorage.removeItem(AuthTokenName);
            this.store.dispatch(new appDataActions.ToggleIsLoading(false));
            this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
            this.router.navigate(['young']);
        }
    };
    HomeComponent.prototype.submit = function () {
        if (!this.isGlobalUser) {
            this.updateUser();
        }
        else {
            // Global user, direct to login.
            this.router.navigate(['/login']);
        }
    };
    return HomeComponent;
}());
export { HomeComponent };
