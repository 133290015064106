import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '~environments/environment';
import { ErrorHandler } from '~app/services/service-handlers';
import { UserResponse, UserUpdateRequest } from '~models/user';
import 'rxjs/add/operator/catch';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie';
import { formatDateForHapi } from '~app/logical-objects/helpers/date-time';
import { MetadataTypes } from '~app/constants/church';
import { isNullOrUndefined } from 'util';
var UserService = /** @class */ (function () {
    function UserService(httpClient, store, cookieService) {
        this.httpClient = httpClient;
        this.store = store;
        this.cookieService = cookieService;
        this._Login_Endpoint = '/authentication';
        this._Logout_Endpoint = '/logout';
        this._Terms_And_Conditions = '/termsandconditionsaccepted';
        this._Get_User_Endpoint = '/users?type=full';
        this._Save_User_Endpoint = '/users';
        this._Get_Nonce_Endpoint = '/nonce';
        this._Get_Token_Endpoint = '/token';
    }
    UserService.prototype.authenticate = function (userLogin) {
        var body = {
            loginEmail: userLogin.loginEmail,
            password: userLogin.password
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Login_Endpoint, body)
            .catch(ErrorHandler);
    };
    UserService.prototype.getNonce = function (token) {
        var headers = new HttpHeaders({
            authorization: token
        });
        return this.httpClient
            .get(environment.marlinApiUrl + this._Get_Nonce_Endpoint, { headers: headers })
            .catch(ErrorHandler);
    };
    UserService.prototype.getToken = function (nonce) {
        var headers = new HttpHeaders({
            authorization: nonce
        });
        return this.httpClient
            .get(environment.marlinApiUrl + this._Get_Token_Endpoint, { headers: headers })
            .catch(ErrorHandler);
    };
    UserService.prototype.updateUser = function (user) {
        var dateOfBirth = '';
        if (user.dateOfBirth) {
            dateOfBirth = formatDateForHapi(user.dateOfBirth.value);
        }
        var body = {
            firstName: user.firstName,
            familyName: user.familyName,
            genderId: user.genderId,
            mobilePhone: user.mobilePhone,
            homePhone: user.homePhone,
            ageGroupId: user.ageGroupId,
            dateOfBirth: dateOfBirth,
            address1: user.address,
            suburb: user.suburb,
            postCode: user.postCode,
            countryId: user.countryId,
            serviceId: user.churchLocationService.service,
            involvementId: user.involvementId
        };
        return this.httpClient
            .patch(environment.marlinApiUrl + this._Save_User_Endpoint, body)
            .catch(ErrorHandler);
    };
    UserService.prototype.updateTermsAndConditions = function (isAccepted) {
        var body = {
            termsAndConditionsAccepted: isAccepted
        };
        return this.httpClient
            .patch(environment.marlinApiUrl + this._Terms_And_Conditions, body)
            .catch(ErrorHandler);
    };
    UserService.prototype.registerUser = function (userRegistration) {
        var storedConsents = [];
        this.store
            .select(function (state) { return state.metadata[MetadataTypes.Consents]; })
            .take(1)
            .subscribe(function (consentData) {
            if (consentData) {
                storedConsents = storedConsents.concat(consentData);
            }
        });
        var formConsents = userRegistration.consents.value;
        if (isNullOrUndefined(formConsents)) {
            formConsents = userRegistration.consents;
        }
        else {
            for (var key in formConsents) {
                if (formConsents[key]) {
                    formConsents['consentType6'] = true;
                }
            }
        }
        var returnConsents = [];
        var _loop_1 = function (key) {
            if (formConsents.hasOwnProperty(key)) {
                var typeID_1 = key.replace('consentType', '');
                var wordingID = storedConsents.filter(function (consent) { return consent.consentTypeID == typeID_1; })[0].id;
                returnConsents.push({
                    consentWordingID: wordingID,
                    agreed: formConsents[key],
                    consentTypeID: typeID_1
                });
            }
        };
        for (var key in formConsents) {
            _loop_1(key);
        }
        var dateOfBirth = '';
        if (userRegistration.dateOfBirth) {
            dateOfBirth = formatDateForHapi(userRegistration.dateOfBirth.value);
        }
        var body = {
            loginEmail: userRegistration.loginEmail,
            firstName: userRegistration.firstName,
            familyName: userRegistration.familyName,
            genderId: userRegistration.genderId,
            mobilePhone: userRegistration.mobilePhone,
            homePhone: userRegistration.homePhone,
            ageGroupId: userRegistration.ageGroupId,
            churchId: userRegistration.churchLocationService.value.church,
            locationId: userRegistration.churchLocationService.value.location,
            serviceId: userRegistration.churchLocationService.value.service,
            dateOfBirth: dateOfBirth,
            involvementId: userRegistration.involvementId,
            recaptcha: userRegistration.recaptcha,
            consents: returnConsents,
            invitationToken: userRegistration.invitationToken
        };
        var headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post(environment.marlinApiUrl + this._Save_User_Endpoint, body, { headers: headers }).catch(ErrorHandler);
    };
    UserService.prototype.getUser = function (token) {
        var headers = new HttpHeaders({
            authorization: token
        });
        return this.httpClient
            .get(environment.marlinApiUrl + this._Get_User_Endpoint, { headers: headers })
            .catch(ErrorHandler);
    };
    UserService.prototype.logoutUser = function () {
        return this.httpClient.post(environment.marlinApiUrl + this._Logout_Endpoint, {}).catch(ErrorHandler);
    };
    return UserService;
}());
export { UserService };
export var SuccessMessages = {
    success: 'OK',
    successDetails: 'OK_UPDATE_DETAILS',
    successVerify: 'OK_VERIFY'
};
